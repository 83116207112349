<template>
    <a-card :bordered="false" class="card-area">
        <div class="search"><!-- 搜索区域 -->
            <div class="items" style="flex: none">
                <div class="one-item">
                    <a-select v-model="selvalue" placeholder="选择类型" style="width:100%" @change="selectChange()">
                        <a-select-option v-for="item in keywordStatus" :value="item.value" :key="item.value">{{
                            item.label }}</a-select-option>
                    </a-select>
                </div>
                <div class="one-item">
                    <a-input v-model="insearch" placeholder="请输入名称查询"></a-input>
                </div>
            </div>
            <div class="operator">
                <a-button type="primary" @click="btsearch">查询</a-button>
                <a-button type="primary" @click="add" style="margin-left:20px;" icon="plus">
                    新增
                </a-button>
            </div>
        </div>
        <a-table :dataSource="tableData" rowKey="id" size="small" :bordered="true" :columns="columns"
            :scroll="{ x: 1000 }">
            <template slot="ftype" slot-scope="text, record">
                <a-tag :color="record.ftype | dictName('keywordStatus', 'color')">{{
                    record.ftype | dictName("keywordStatus")
                    }}
                </a-tag>
            </template>
            <template slot="status" slot-scope="text,record">
                <a-switch :checked="record.status == 1" @change="changeStatus(record, 'status')"
                    :loading1="showLoading" />
            </template>

            <template slot="operation" slot-scope="text, record">
                <a-button type="primary" icon="edit" size="small" @click="modify(record)">
                    修改
                </a-button>
                <a-button type="danger" icon="delete" size="small" style="margin-left:10px" @click="getDelete(record)">
                    删除
                </a-button>
            </template>
        </a-table>
        <modify-modal ref="modifyModal" @success="editSuccess" />
    </a-card>
</template>
<script>
import { DICT } from "@/utils/dict";
import modifyModal from "./modifyKeywordModal";
export default {
    name: "keywordList",
    components: { modifyModal },
    data() {
        return {
            selvalue: 1,
            insearch:"",
            tableData: []
        };
    },
    created() {
        this.searchTable();
    },
    computed: {
        headers() {
            return {
                Authentication: this.$store.state.account.token,
            };
        },
        keywordStatus() {
            return DICT['keywordStatus']
        },
        columns() {
            return [{
                title: "ID",
                width: 50,
                align: "center",
                dataIndex: "id",
            }, {
                title: "类型",
                width: 80,
                align: "center",
                dataIndex: "ftype",
                scopedSlots: { customRender: "ftype" },
            }, {
                title: "名称",
                width: 80,
                align: "center",
                dataIndex: "fname",
            }, {
                title: "状态",
                width: 80,
                align: "center",
                dataIndex: "status",
                scopedSlots: { customRender: "status" },
            }, {
                title: "序号",
                width: 80,
                align: "center",
                dataIndex: "fseq",
            }, {
                title: "说明",
                width: 160,
                align: "center",
                dataIndex: "mark",
            }, {
                title: "操作",
                width: 200,
                align: "center",
                dataIndex: "operation",
                scopedSlots: { customRender: "operation" },
            },];
        }
    },
    methods: {
        editSuccess() {
            this.searchTable();
        },
        btsearch() {
            this.searchTable();
        },
        add() {
            this.$refs.modifyModal.showModal(null, 'new');
        },
        modify(record) {
            this.$refs.modifyModal.showModal(record, 'edit');
        },
        getDelete(record) {
            let that = this;
            this.$confirm({
                title: "删除确认",
                content: "您确定要删除【" + record.fname + "】的关键词吗？！",
                centered: true,
                onOk() {
                    that.$get("reader/keywordDel", { id: record.id }).then(() => {
                        that.$message.success("删除成功.");
                        that.searchTable();
                    });
                },
            });
        },
        changeStatus(record) {
            let that = this;
            let targetStatus = record.status == 1 ? 0 : 1;
            let data = record;
            data.status = targetStatus;
            that.showLoading = true;
            that.$postJson("reader/keywordAdd", data).then(() => {
                that.showLoading = false;
                let mes = `ID：【${record.id}】设置状态成功`;
                record.status = targetStatus;
                that.$message.success(mes);
                this.searchTable();
            }).catch(() => {
                that.showLoading = false;
            });
        },
        searchTable() {
            let that = this;
            that.showLoading = true;
            that.$post("reader/keywordList", { "ftype": that.selvalue ,"fwhere": that.insearch }).then((res) => {
                that.showLoading = false;
                that.tableData = res.data;
            });
        },
        selectChange(){
            this.searchTable();
        }
    }
}
</script>